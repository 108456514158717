html,
body,
#root {
  height: 100%;
}

#root {
  min-width: 1250px;
}

body {
  background-color: #F4F4F5;
}

@font-face {
  font-family: 'GothamRounded';
  src: url('./fonts/gotham-rounded/GothamRounded-Medium.otf'),
  url('./fonts/gotham-rounded/gothamrounded-medium-webfont.eot'),
  url('./fonts/gotham-rounded/GothamRoundedMedium_21022.ttf')
  format('truetype');
}

@font-face {
  font-family: 'GothamRounded-LightItalic';
  src: url('./fonts/gotham-rounded/GothamRounded-LightItalic.otf'),
  url('./fonts/gotham-rounded/gothamrounded-light-webfont.eot'),
  url('./fonts/gotham-rounded/GothamRoundedLight_21020.ttf')
  format('truetype');
}

@font-face {
  font-family: 'GothamRounded-Bold';
  src: url('./fonts/gotham-rounded/GothamRounded-Bold.otf'),
  url('./fonts/gotham-rounded/gothamrounded-bold-webfont.eot'),
  url('./fonts/gotham-rounded/GothamRoundedBold_21016.ttf') format('truetype');
}

@font-face {
  font-family: 'GothamRounded-Light';
  src: url('./fonts/gotham-rounded/GothamRounded-Light.otf'),
  url('./fonts/gotham-rounded/gothamrounded-light-webfont.eot'),
  url('./fonts/gotham-rounded/GothamRoundedLight_21020.ttf')
  format('truetype');
}

@font-face {
  font-family: 'GothamRounded-Book';
  src: url('./fonts/gotham-rounded/GothamRounded-Book.otf'),
  url('./fonts/gotham-rounded/gothamrounded-medium-webfont.eot'),
  url('./fonts/gotham-rounded/GothamRoundedBook_21018.ttf') format('truetype');
}

@font-face {
  font-family: 'GothamRounded-BookItalic';
  src: url('./fonts/gotham-rounded/GothamRounded-BookItalic.otf'),
  url('./fonts/gotham-rounded/gothamrounded-mediumitalic-webfont.eot'),
  url('./fonts/gotham-rounded/GothamRoundedBook_21018.ttf') format('truetype');
}

@font-face {
  font-family: 'GothamRounded-Ultra';
  src: url('./fonts/gotham-rounded/Gotham-Ultra.otf');
}

@font-face {
  font-family: 'Gotham Book';
  src: url('./gotham/Gotham-Book.otf'), url('./gotham/GothamBook.woff');
}

@font-face {
  font-family: 'Gotham Medium';
  src: url('./gotham/Gotham-Medium.otf'), url('./gotham/GothamMedium.woff');
}

@font-face {
  font-family: 'Gotham Light';
  src: url('./gotham/GothamLight.woff');
}

@font-face {
  font-family: 'Gotham Ultra Italic';
  src: url('./gotham/Gotham-UltraItalic.woff');
}

body {
  font-family: 'Gotham Book', 'Chinese Quote', -apple-system,
  BlinkMacSystemFont, 'Segoe UI', Roboto, 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/* Override in global, this elements nested in body directly */
.ant-select-dropdown-menu-item {
  padding: 8px 11px !important;
  font-family: 'GothamRounded-Book', sans-serif;
}
.ant-select-dropdown-menu-item:not(:last-of-type) {
  border-bottom: 1px solid #eeeeee !important;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #4a90e2;
}
