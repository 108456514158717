html,
body,
#root {
  height: 100%;
}

#root {
  min-width: 1250px;
}

body {
  background-color: #F4F4F5;
}

@font-face {
  font-family: 'GothamRounded';
  src: url(/static/media/GothamRounded-Medium.4518b6f0.otf),
  url(/static/media/gothamrounded-medium-webfont.0e7acd42.eot),
  url(/static/media/GothamRoundedMedium_21022.dffb2b80.ttf)
  format('truetype');
}

@font-face {
  font-family: 'GothamRounded-LightItalic';
  src: url(/static/media/GothamRounded-LightItalic.8a29db32.otf),
  url(/static/media/gothamrounded-light-webfont.25b311a3.eot),
  url(/static/media/GothamRoundedLight_21020.1549fe8f.ttf)
  format('truetype');
}

@font-face {
  font-family: 'GothamRounded-Bold';
  src: url(/static/media/GothamRounded-Bold.a17abd06.otf),
  url(/static/media/gothamrounded-bold-webfont.abaf8234.eot),
  url(/static/media/GothamRoundedBold_21016.ae8f19f4.ttf) format('truetype');
}

@font-face {
  font-family: 'GothamRounded-Light';
  src: url(/static/media/GothamRounded-Light.52ef94ed.otf),
  url(/static/media/gothamrounded-light-webfont.25b311a3.eot),
  url(/static/media/GothamRoundedLight_21020.1549fe8f.ttf)
  format('truetype');
}

@font-face {
  font-family: 'GothamRounded-Book';
  src: url(/static/media/GothamRounded-Book.e40570a3.otf),
  url(/static/media/gothamrounded-medium-webfont.0e7acd42.eot),
  url(/static/media/GothamRoundedBook_21018.d31d488d.ttf) format('truetype');
}

@font-face {
  font-family: 'GothamRounded-BookItalic';
  src: url(/static/media/GothamRounded-BookItalic.51a35212.otf),
  url(/static/media/gothamrounded-mediumitalic-webfont.38804eda.eot),
  url(/static/media/GothamRoundedBook_21018.d31d488d.ttf) format('truetype');
}

@font-face {
  font-family: 'GothamRounded-Ultra';
  src: url(/static/media/Gotham-Ultra.b8d72cb0.otf);
}

@font-face {
  font-family: 'Gotham Book';
  src: url(/static/media/Gotham-Book.f4cf72d2.otf), url(/static/media/GothamBook.41eebc60.woff);
}

@font-face {
  font-family: 'Gotham Medium';
  src: url(/static/media/Gotham-Medium.d065e5c1.otf), url(/static/media/GothamMedium.ba7ee4ff.woff);
}

@font-face {
  font-family: 'Gotham Light';
  src: url(/static/media/GothamLight.f0de7009.woff);
}

@font-face {
  font-family: 'Gotham Ultra Italic';
  src: url(/static/media/Gotham-UltraItalic.a5048d94.woff);
}

body {
  font-family: 'Gotham Book', 'Chinese Quote', -apple-system,
  BlinkMacSystemFont, 'Segoe UI', Roboto, 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/* Override in global, this elements nested in body directly */
.ant-select-dropdown-menu-item {
  padding: 8px 11px !important;
  font-family: 'GothamRounded-Book', sans-serif;
}
.ant-select-dropdown-menu-item:not(:last-of-type) {
  border-bottom: 1px solid #eeeeee !important;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #4a90e2;
}

